import './styles/fonts.ts';
import '@/styles/theme.scss';
import Vue, { h } from 'vue';
import { createApp } from 'vue-demi';
import Component from 'vue-class-component';
import App from '@/app.vue';
import store from '@/store';
import router from '@/router';
import { focusOnInsertPlugin } from '@/plugins/focusOnInsert';
import { i18nPlugin } from './i18n';
import { isoCountryPlugin } from './plugins/isoCountries';
import StandardButton from '@/components/standard-components/StandardButton.vue';
import StandardModal from '@/components/standard-components/StandardModal.vue';
import StandardFormDropdown from '@/components/standard-components/StandardFormDropdown.vue';
import HelpPopover from '@/components/standard-components/HelpPopover.vue';
import SectionTitle from '@/components/standard-components/SectionTitle.vue';
import { AuthProvider } from '@/auth';
import TranslationMixin from './mixins/TranslationMixin';
import { Settings } from 'luxon';
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query';
import { fontAwesomePlugin } from './plugins/fontAwesome';
import { AxiosError } from 'axios';
import { bootstrapPlugin } from './plugins/bootstrap';
import { apmPlugin } from './plugins/apm';
import { ClientConstants } from './constants/ClientConstants.js';

/**
 * Set
 */
Settings.defaultZoneName = 'utc';

Vue.use(isoCountryPlugin);
Vue.use(i18nPlugin);

Vue.mixin(TranslationMixin);

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave']);

window.graphs = {};
window.v5graphs = {};
window.titles = {};
window.subtitles = {};
window.legends = {};
window.footers = {};

Vue.use(fontAwesomePlugin);
Vue.use(bootstrapPlugin);
Vue.use(focusOnInsertPlugin);
Vue.use(apmPlugin);
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('visible', function (el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error) => {
        if (failureCount >= 3) return false;
        if (((error as AxiosError).response?.status as number) >= 500) return false;
        return true;
      },
      staleTime: 20 * 1000,
    },
  },
});
Vue.use(VueQueryPlugin, {
  queryClient,
});

Vue.config.productionTip = false;
Vue.config.devtools = import.meta.env.VITE_MODE !== 'production';
// WAA-10259: Disabled due to performance issues with Firefox. Potentially re-enable when upgrading to vue3.
Vue.config.performance = false;

Vue.component('StandardButton', StandardButton);
Vue.component('StandardModal', StandardModal);
Vue.component('StandardFormDropdown', StandardFormDropdown);
Vue.component('HelpPopover', HelpPopover);
Vue.component('SectionTitle', SectionTitle);

createApp({
  router,
  store,
  render: () => h(AuthProvider, [h(App)]),
}).mount('#app');

// Ensure all local links redirect in bofa and ignore target=_blank
if (import.meta.env.VITE_CLIENT === ClientConstants.BOFA) {
  window.document.body.addEventListener(
    'click',
    (e) => {
      const target = e.target as HTMLElement;
      const link = target.closest('a');
      if (
        link &&
        link.href.startsWith(window.location.origin) &&
        !link.href.startsWith(`${window.location.origin}/console/`)
      ) {
        e.preventDefault();
        router.push(link.href.slice(window.location.origin.length));
      }
    },
    { capture: true },
  );
}
