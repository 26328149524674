/**
 * TODO: consolidate CLUSTERING_ANALYSIS and PRINCIPAL_COMPONENT
 */
export enum RouteName {
  ABOUT = 'About',
  ANALYTICS = 'Analytics',
  NEWS = 'News',
  INSIGHTS = 'Insights',
  BROCHURE_DOWNLOAD = 'Brochure Download',
  RISK_FACTOR_DOWNLOAD = 'Risk Factor Download',
  DATA = 'Data',
  CLUSTERING_ANALYSIS = 'Principal Component Analysis',
  CLUSTERING_ANALYSIS_PDF = 'Principal Component Analysis PDF',
  CONSTITUENT_RISK = 'Constituent Risk',
  CONSTITUENT_RISK_PDF = 'Constituent Risk PDF',
  CONSTITUENT_POSITION = 'Constituent Position',
  DROP_ZONE = 'DropZone',
  FACTOR_DECOMPOSITION = 'Factor Decomposition',
  FACTOR_DECOMPOSITION_PORTFOLIO = 'Factor Decomposition Portfolio',
  FACTOR_DECOMPOSITION_PORTFOLIO_PDF = 'Factor Decomposition Portfolio PDF',
  FACTOR_DECOMPOSITION_STRATEGY = 'Factor Decomposition Strategy',
  FACTOR_DECOMPOSITION_STRATEGY_PDF = 'Factor Decomposition Strategy PDF',
  KNOWLEDGE_BASE = 'Knowledge Base',
  FOLDER = 'Portfolio Selection',
  HOME = 'Home',
  INACTIVE = 'Inactive',
  MORNINGSTAR = 'Morningstar',
  MY_LAB = 'My Lab',
  MY_LAB_PROXY_TABLE = 'ProxyTable',
  TRADE_REPOSITORY = 'Trade Repository',
  NOT_FOUND = 'Not Found',
  PERFORMANCE_ATTRIBUTION = 'Performance Contribution',
  PERFORMANCE_ATTRIBUTION_PDF = 'Performance Attribution PDF',
  PORTFOLIO_CONSTRUCTION = 'Portfolio Construction',
  PORTFOLIO_FACTSHEET = 'Portfolio Factsheet',
  PORTFOLIO_FACTSHEET_PDF = 'Portfolio Factsheet PDF',
  PRINCIPAL_COMPONENT = 'Principal Component',
  PRIVACY_POLICY = 'Privacy Policy',
  RESOURCES = 'Resources',
  RESOURCES_API_CLIENT = 'API Documentation',
  RESOURCES_API_PREMIALAB = 'API Developer',
  RESOURCES_FILE_DOWNLOADS = 'PlbPy',
  RESOURCES_RISK_REPORT_DOWNLOADS = 'Risk Report Downloads',
  PURE_FACTORS = 'Pure Factor Monthly Report',
  WHITEPAPER = 'Whitepaper',
  STRATEGY_FACTSHEET = 'Strategy Factsheet',
  STRATEGY_FACTSHEET_PDF = 'Strategy Factsheet PDF',
  TERMS = 'Terms',
  UNAUTHORIZED = 'Unauthorised',
  LOGIN = 'Login',
  LOGOUT = 'Logout',

  // ARTICLES
  ARTICLES = 'Articles Page',
  ARTICLES_ADMIN = 'Articles Admin',
  ARTICLE_FEED = 'Article Feed',
  ARTICLE_EDIT = 'Edit Article',

  // ADMIN CONSOLE
  CONSOLE = 'Admin Console',
  ACCOUNT_MANAGEMENT = 'Account Management',
  TRACK_MANAGEMENT = 'Track Management',
  TEAM_MANAGEMENT = 'Team Management',
  FILE_MANAGEMENT = 'File Management',
  VIEW_FILE = 'View File',
  CONSTITUENT_MANAGEMENT = 'Constituent Management',
  PROXY_CHECK = 'Proxy Check',
  VIEW_CONSTITUENT = 'View Constituent',
  VIEW_FILES = 'View Files',
  VIEW_POSITIONS = 'View Positions',
  VIEW_POSITION = 'View Position',
  RISK_MANAGEMENT = 'Risk Management',
  RISK_MANAGEMENT_RISK_PORTFOLIOS = 'Risk Portfolios',
  RISK_MANAGEMENT_RPP_JOB_STATUS = 'RPP Job Status',
  RISK_MANAGEMENT_RISK_KPI = 'Risk KPI',
  RISK_MANAGEMENT_RISK_VALIDATION = 'Risk Validation',
  API_KEYS = 'Api Keys',
  SETTINGS = 'Settings',
  PLATFORM_HEALTH = 'Platform Health',
  SUBSCRIPTIONS = 'Subscriptions',
  TRACK_SYNC_JOBS = 'Track Sync Jobs',
  EXCEL_JOBS = 'Excel Jobs',
  EDIT_USER = 'Edit User',
  CREATE_STRATEGY = 'Add Strategy',
  EDIT_STRATEGY = 'Edit Strategy',
  EDIT_TEAM = 'Edit Team',
  CONTENT_MANAGEMENT = 'Content Management',
  PRIVATE_TRACKS = 'User Tracks',
  VIEW_PRIVATE_TRACK = 'View User Track',
  INSTRUMENTS_MANAGEMENT = 'Instruments Management',
  FUTURES = 'Futures',
  STOCKS = 'Stocks',
  BONDS = 'Bonds',
  FUNDS = 'Funds',
  ETFS = 'ETFs',
  MARKET_INDICES = 'Market Indices',
  CDS_INDICES = 'CDS Indices',
  VIEW_CDS_INDEX = 'View CDS Index',
  CUSTOM_INDICES = 'Custom Indices',
  VIEW_CUSTOM_INDEX = 'View Custom Index',
  VIEW_INSTRUMENT = 'View Instrument',
  VIEW_FUTURE = 'View Future',
  CLIENT_REQUESTS = 'Client Requests',
  PLATFORM_ANALYTICS = 'Platform Analytics',
  PEFORMANCE_CONTRIBUTION_CONFIG = 'Performance Contribution (Config)',
  PEFORMANCE_CONTRIBUTION_BACKFILL = 'Performance Contribution (Backfill)',
  PEFORMANCE_CONTRIBUTION_GENERATED_DATA = 'Performance Contribution (Generated Data)',
  PEFORMANCE_CONTRIBUTION_JOB_STATUS = 'Performance Contribution (Job Status)',
  EDIT_PERFORMANCE_CONTRIBUTION = 'Edit Performance Contribution',
  CREATE_PERFORMANCE_CONTRIBUTION_CONFIG = 'Create Performance Contribution Config',
  PDF_MANAGEMENT_ADMIN = 'PDF Management',
  SEO_MANAGEMENT_ADMIN = 'SEO Management',
  DATABASE = 'Database',
  MIGRATIONS_ADMIN = 'Migrations',
  INSPECT_MIGRATION = 'Inspect Migration',
  MUTATIONS_ADMIN = 'Mutations',
  INSPECT_MUTATION = 'Inspect Mutation',
  CUSTOM_REPORT = 'Custom Report',
  CUSTOM_REPORT_JOB_STATUS = 'Custom Report Job Status',
  CUSTOM_REPORT_RISK_EXCEL_JOB_STATUS = 'Custom Report Risk Excel Job Status',
  QA = 'QA',
  TRACK_QA = 'Track QA',
  CONSTITUENT_QA = 'Constituent QA',
  VIEW_TRACK_QA = 'View Track QA',
  VIEW_CONSTITUENT_QA = 'View Constituent QA',
  REPORT_FIELDS = 'Report Fields',
  VIEW_REPORT_FIELD = 'View Report Field',
  EDIT_CLIENT_REQUEST_PROFILE = 'Edit Client Request Profile',

  // User Setting

  USER_SETTING = 'User Settings',

  EQUITY_BASKET = 'Equity Basket',

  UNIVERSE = 'Universe',

  TEST_PDF_PORTFOLIO = 'Test PDF Portfolio',
}

/**
 * Set of route names that shows portfolios
 */
export const portfolioPages = new Set([
  RouteName.FACTOR_DECOMPOSITION_PORTFOLIO,
  RouteName.FACTOR_DECOMPOSITION_PORTFOLIO_PDF,
  RouteName.PORTFOLIO_FACTSHEET,
  RouteName.PORTFOLIO_CONSTRUCTION,
  RouteName.PORTFOLIO_FACTSHEET_PDF,
  RouteName.CLUSTERING_ANALYSIS,
  RouteName.CLUSTERING_ANALYSIS_PDF,
  RouteName.CONSTITUENT_RISK,
  RouteName.CONSTITUENT_RISK_PDF,
  RouteName.EQUITY_BASKET,
  RouteName.TEST_PDF_PORTFOLIO,
]);

export const strategyPages = new Set([
  RouteName.STRATEGY_FACTSHEET,
  RouteName.FACTOR_DECOMPOSITION_STRATEGY,
  RouteName.PERFORMANCE_ATTRIBUTION,
]);

export const navPages = new Set([
  RouteName.INSIGHTS,
  RouteName.ARTICLES,

  RouteName.DATA,

  RouteName.RESOURCES,
  RouteName.MY_LAB,
  RouteName.DROP_ZONE,
  RouteName.KNOWLEDGE_BASE,

  RouteName.USER_SETTING,

  RouteName.CONSOLE,
  RouteName.TRACK_MANAGEMENT,
  RouteName.ACCOUNT_MANAGEMENT,
  RouteName.EDIT_USER,
  RouteName.CREATE_STRATEGY,
  RouteName.TEAM_MANAGEMENT,
  RouteName.EDIT_TEAM,
  RouteName.CONTENT_MANAGEMENT,
]);
